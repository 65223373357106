import React, { useState } from 'react';
import {files} from "./filesData"

const Content = () => {

  const verifyCode = async (fileId, enteredCode) => {
    const file = files.find((f) => f.id === fileId);
    if (file) {
      return file.code === enteredCode;
    }
    return false;
  };

  const FileItem = ({ file }) => {
    const [code, setCode] = useState('');
    const [showDownload, setShowDownload] = useState(false);
    const [fileError, setFileError] = useState('');

    const handleVerify = async () => {
      const success = await verifyCode(file.id, code);
      setShowDownload(success);
      if (!success) {
        setFileError('Invalid code. Please try again.');
      } else {
        setFileError('');
      }
    };

    return (
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6 border-l-4 border-blue-500 hover:border-blue-700 transition-all duration-300">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{file.name}</h3>
        <p className="text-gray-700 mb-4">{file.description}</p>
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter access code"
            className="border border-gray-300 rounded-lg py-2 px-4 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full sm:w-auto mb-4 sm:mb-0"
          />
          <button
            onClick={handleVerify}
            className="bg-blue-600 text-white rounded-lg px-6 py-2 hover:bg-blue-700 transition duration-300 w-full sm:w-auto focus:outline-none"
          >
            Verify Code
          </button>
        </div>
        {fileError && <p className="text-red-600 mt-3">{fileError}</p>}
        {showDownload && (
          <a
            href={file.driveLink}
            download={file.name}
            className="mt-4 inline-block bg-green-500 text-white rounded-lg px-6 py-2 hover:bg-green-600 transition duration-300"
          >
            Download {file.name}
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-3xl mx-auto py-10 px-6">
      {files.map((file) => (
        <FileItem key={file.id} file={file} />
      ))}
    </div>
  );
};

export default Content;
