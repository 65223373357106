import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";  // Added useLocation
import "./App.css";
import Home from "./pages/home/Home.jsx";
import Course from "./pages/course/Course";
import Contact from "./pages/contact/Contact";
import Signup from './pages/register/signup/Signup.jsx';
import Login from './pages/register/logins/Login.jsx';
import ResetPassword from './pages/register/reset/ResetPassword.jsx';
import ForgotPassword from './pages/register/forgot/Forgotpassword.jsx';
import WhatsAppFloat from './component/WhatsappFloat.jsx'; 
import TermsOfService from "./pages/TermsOfService.jsx";
import Navbar from "./component/navbar/Navbar.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import ReturnRefundPolicy from "./pages/ReturnRefundPolicy.jsx";
import ShippingDeliveryPolicy from "./pages/ShippingDeliveryPolicy.jsx";
import PaymentSuccess from "./pages/PaymentSuccess.jsx";
import Contribute from "./pages/contribute/Contribute.jsx";
import ContributionSuccess from "./pages/ContributionSuccess.jsx";
import Content from "./pages/content/Content.jsx";

function App() {
  const [showNavbar, setShowNavbar] = useState(true);  // State for showing the Navbar
  const location = useLocation();  // Hook to get the current location

  // Effect to handle the visibility of the Navbar based on the path
  useEffect(() => {
    const noNavbarPaths = ["/login", "/signup", "/forgotPassword", "/resetPassword"];
    if (noNavbarPaths.includes(location.pathname)) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showNavbar && <Navbar/>}  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Course />} />
        <Route path="/content" element={<Content />} />
        <Route path="/contribute" element={<Contribute />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/contributionsuccess" element={<ContributionSuccess />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/returnRefundPolicy" element={<ReturnRefundPolicy/>} />
        <Route path="/shippingDeliveryPolicy" element={<ShippingDeliveryPolicy />} />
      </Routes>
      <WhatsAppFloat />
    </>
  );
}

export default App;