import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardImage from "../../asset/images/Medjeex_Logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleContactClick = () => {
    navigate("/contact");
    setIsOpen(false);
    setActiveLink("Contact Us");
  };

  const handleCourseClick = () => {
    navigate("/courses");
    setIsOpen(false);
    setActiveLink("Courses");
  };

  const handleContentClick = () => {
    navigate("/content");
    setIsOpen(false);
    setActiveLink("Content");
  };

  const handleLoginRegisterClick = () => {
    setShowAnimation(true);
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
    setActiveLink("");
    navigate("/");
    setIsOpen(false);
  };

  const handleContributeClick = () => {
    navigate("/contribute");
    setIsOpen(false);
    setActiveLink("Contribute");
  };

  const getLinkClass = (linkName) => {
    return activeLink === linkName
      ? "font-bold underline text-blue-700"
      : "text-gray-900 dark:text-white";
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest("button[data-drawer-toggle]")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-gray-300 border-gray-200 dark:bg-gray-900">
      <div className="flex flex-wrap items-center justify-between mx-auto px-4 py-2">
        <div className="flex items-center space-x-4">
          <a
            href="/"
            className="flex items-center space-x-4 rtl:space-x-reverse cursor-pointer"
          >
            <img src={CardImage} className="h-16 sm:h-14 md:h-20" alt="Logo" />
          </a>
          <ul className="hidden md:flex font-medium text-xl flex-row space-x-8">
            <li>
              <a
                onClick={handleCourseClick}
                className={`${getLinkClass(
                  "Courses"
                )} block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:hover:text-white md:dark:hover:text-blue-500 cursor-pointer`}
              >
                Course
              </a>
            </li>
            <li>
              <a
                onClick={handleContentClick}
                className={`${getLinkClass(
                  "Content"
                )} block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:hover:text-white md:dark:hover:text-blue-500 cursor-pointer`}
              >
                Content
              </a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-center text-center mt-2 md:mt-0">
          <span className="text-lg sm:text-4xl font-extrabold text-gray-900 dark:text-white">
            MEDJEEX
          </span>
          <hr className="w-full border-t-2 border-gray-900 dark:border-white my-1" />
          <span className="text-md sm:text-2xl text-gray-700 dark:text-gray-300">
            NEET | JEE | FOUNDATION
          </span>
        </div>

        <ul className="hidden md:flex font-medium text-xl flex-row space-x-8">
          <li>
            <a
              onClick={handleContactClick}
              className={`${getLinkClass(
                "Contact Us"
              )} block py-4 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:hover:text-white md:dark:hover:text-blue-500 cursor-pointer`}
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              onClick={handleContributeClick}
              className={`${getLinkClass(
                "Contribute"
              )} block py-4 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 dark:hover:text-white md:dark:hover:text-blue-500 cursor-pointer`}
            >
              Contribute
            </a>
          </li>
          {user ? (
            <li className="relative">
              <button
                onClick={toggleDropdown}
                className="text-white font-bold py-2 px-6 rounded-full flex items-center"
              >
                <img
                  src={user.imageUrl}
                  alt={`${user.name}'s avatar`}
                  className="w-10 h-10 rounded-full object-cover"
                />
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-xl shadow-lg z-10">
                  <button
                    onClick={handleLogout}
                    className="block w-full px-4 py-2 text-left rounded-xl text-gray-900 hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </li>
          ) : (
            <li>
              <button
                onClick={handleLoginRegisterClick}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-4 px-6 rounded-full hover:from-purple-500 hover:to-blue-500 transition-colors duration-300"
              >
                Login/Register
              </button>
            </li>
          )}
        </ul>

        <button
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      <div
        ref={sidebarRef}
        id="default-sidebar"
        className={`fixed top-0 left-0 w-64 h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } bg-gray-200 border-r border-gray-300 dark:bg-gray-800 dark:border-gray-700 z-50`}
      >
        <div className="flex flex-col items-center p-4 space-y-4">
          <a
            href="/"
            className="text-xl font-semibold text-gray-900 dark:text-white"
          >
            MEDJEEX
          </a>
          <a
            onClick={() => {
              navigate("/");
              setActiveLink("Home");
              toggleMenu();
            }}
            className={`block py-2 px-4 rounded hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white dark:hover:text-white cursor-pointer ${
              activeLink === "Home" ? "bg-gray-100 dark:bg-gray-700" : ""
            }`}
          >
            Home
          </a>
          <a
            onClick={handleCourseClick}
            className={`block py-2 px-4 rounded hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white dark:hover:text-white cursor-pointer ${
              activeLink === "Courses" ? "bg-gray-100 dark:bg-gray-700" : ""
            }`}
          >
            Courses
          </a>
          <a
            onClick={handleContentClick}
            className={`block py-2 px-4 rounded hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white dark:hover:text-white cursor-pointer ${
              activeLink === "Content" ? "bg-gray-100 dark:bg-gray-700" : ""
            }`}
          >
            Content
          </a>
          <a
            onClick={handleContactClick}
            className={`block py-2 px-4 rounded hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white dark:hover:text-white cursor-pointer ${
              activeLink === "Contact Us" ? "bg-gray-100 dark:bg-gray-700" : ""
            }`}
          >
            Contact Us
          </a>
          <a
            onClick={handleContributeClick}
            className={`block py-2 px-4 rounded hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white dark:hover:text-white cursor-pointer ${
              activeLink === "Contribute" ? "bg-gray-100 dark:bg-gray-700" : ""
            }`}
          >
            Contribute
          </a>
          {user ? (
            <>
              <img
                src={user.imageUrl}
                alt={`${user.name}'s avatar`}
                className="w-10 h-10 rounded-full object-cover"
              />

              <button
                onClick={() => {
                  handleLogout();
                  toggleMenu();
                }}
                className="block bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-2 px-6 rounded-full hover:from-purple-500 hover:to-blue-500 transition-colors duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                handleLoginRegisterClick();
                toggleMenu();
              }}
              className="block py-2 px-4 rounded bg-blue-500 text-white hover:bg-blue-600"
            >
              Login/Register
            </button>
          )}
        </div>
      </div>

      {showAnimation && (
        <>
          <div className="fixed inset-0 flex items-center justify-center bg-white z-40">
            <img
              src={CardImage}
              alt="Medjeex Logo"
              className="h-48 w-48"
              style={{ animation: "scaleIn 1s forwards" }}
            />
          </div>

          <style>
            {`
              @keyframes scaleIn {
                0% {
                  opacity: 0;
                  transform: scale(0.5);
                }
                100% {
                  opacity: 1;
                  transform: scale(1);
                }
              }
            `}
          </style>
        </>
      )}
    </nav>
  );
};

export default Navbar;
