import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ProductCard = ({
  image,
  title,
  rating,
  realPrice,
  offerPrice,
  description,
}) => {
  const navigate = useNavigate();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const isLoggedIn = () => !!localStorage.getItem("token");
  const getUserDetails = () => JSON.parse(localStorage.getItem("user"));

  const handleDescriptionClick = () => {
    setIsDescriptionVisible(true);
  };

  const closeDescriptionPopup = () => {
    setIsDescriptionVisible(false);
  };

  const handleBuyNow = async (amount) => {
    if (!isLoggedIn()) {
      navigate("/login");
      return;
    }
    const user = getUserDetails();
    try {
      const {
        data: { key },
      } = await axios.get("https://medjeex.com/api/v1/getkey");

      const {
        data: { order },
      } = await axios.post("https://medjeex.com/api/v1/checkout", {
        amount,
      });

      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: title,
        description: "MEDJEEX EDUTECH PRIVATE LIMITED",
        image: image,
        order_id: order.id,
        callback_url: "https://medjeex.com/api/v1/paymentverification",
        prefill: {
          name: user?.name || "Enter Name",
          email: user?.email || "Enter Email",
          contact: user?.phoneNumber || "Enter Contact",
        },
        notes: {
          user_id: user._id,
          product_title: title
        },
        theme: {
          color: "#8A8A8A",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("There was an error with the payment process!", error);
      alert("Failed to initiate the payment. Please try again.");
    }
  };

  return (
    <div className="w-full max-w-xs mt-5 ml-10 mr-10 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <img className="p-4 rounded-t-lg" src={image} alt="product image" />
      </a>
      <div className="px-4 pb-4">
        <a href="#">
          <h5 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        <div className="flex items-center mt-2 mb-4">
          <div className="flex items-center space-x-1 rtl:space-x-reverse">
            {[...Array(5)].map((_, index) => (
              <svg
                key={index}
                className={`w-4 h-4 ${
                  index < rating
                    ? "text-yellow-300"
                    : "text-gray-200 dark:text-gray-600"
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
            ))}
          </div>
          <span className="bg-gray-300 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-gray-400 dark:text-gray-800 ml-3">
            {rating}.0
          </span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-around">
            <span className="text-2xl font-normal pr-2 text-gray-900 dark:text-white line-through">
              ₹{realPrice}
            </span>

            <span className="text-2xl font-bold pr-2 text-gray-900 dark:text-white">
              ₹{offerPrice}
            </span>
          </div>
          <a
            onClick={handleDescriptionClick}
            className="text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xl px-10 py-2 transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-2xl hover:from-blue-600 hover:to-purple-700 cursor-pointer overflow-hidden"
          >
            Join
          </a>
        </div>
      </div>

      {/* Course Description Modal */}
      {isDescriptionVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
            {/* Close Button */}
            <button
              onClick={closeDescriptionPopup}
              className="absolute top-2 right-2 flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-500 hover:text-gray-700"
              style={{ zIndex: 100 }}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-2">{title} Details</h2>
            <ul className="list-disc pl-5 mb-4">
              {description.map((point, index) => (
                <li key={index} className="mb-2">
                  {point}
                </li>
              ))}
            </ul>
            <button
              onClick={() => handleBuyNow(offerPrice)}
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Buy Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
