const downloadLinks = [
    'https://drive.google.com/uc?export=download&id=1MzewzPxeeKPYbj6-64oBvZEls4pnnxfg', //0
    'https://drive.google.com/uc?export=download&id=1cLVHcni3-dcE01P4g7lgeskpUgnvoFnZ', //1
    'https://drive.google.com/uc?export=download&id=1WYEZtGV1l0nP0N8vssdbG7dqykufqQrI', //2
    'https://drive.google.com/uc?export=download&id=1ku41BhwMRnPI9b4hezQQ35bsDsP1Dh2u', //3
    'https://drive.google.com/uc?export=download&id=1ApMDvv5EjTLiKvYr2NtL6IY7siZdjnko', //4
    'https://drive.google.com/uc?export=download&id=1tfZKUO2_yOIg-8feID2A4dBgHX4Mimps', //5
    'https://drive.google.com/uc?export=download&id=1DkWq9Cw0UM0Vx_mcTREMgboreZ4_LS-M', //6
    'https://drive.google.com/uc?export=download&id=1rdU5MGeEQxX2soZaboGDGR85O9ld5e1r', //7
    'https://drive.google.com/uc?export=download&id=1YgvCg29rAZSyatQpSWJ_6iueDQL0mfRu', //8
    'https://drive.google.com/uc?export=download&id=1wDPDX0IdDqxOY1iTmv_9aqfFyp-d8uDJ', //9
    'https://drive.google.com/uc?export=download&id=1KspVkAe3j5O4RmTrueaardctiTLZt9D2', //10
    'https://drive.google.com/uc?export=download&id=1eUMqiZEFu9puCjaLV00f84NG1ot5oL15', //11
    'https://drive.google.com/uc?export=download&id=1WND-C3IifHsmmv30k2gyzWwQR-oJ2Y93', //12
    'https://drive.google.com/uc?export=download&id=1hYYPJbNjpVmS3r2lvc5zHyX0peccH66s', //13
    'https://drive.google.com/uc?export=download&id=1gaY2AScClCxsnfRMCguiSFg8xuYpbdCd', //14
    'https://drive.google.com/uc?export=download&id=1__mxJCFMIsWYzuefx5V_ylfalAMnJonx', //15
    'https://drive.google.com/uc?export=download&id=1E6WlEiqa8anZGXaZmpsd1Vvi_3u45CL7', //16
    'https://drive.google.com/uc?export=download&id=1D1GI6LZjI3zWNq0pokwZxHJMQ9kPeNHz', //17
    'https://drive.google.com/uc?export=download&id=1bJb2Vl9BamjQk7e2GARY-T_QjIIzzBP9', //18                                                                                                                                       ,
    'https://drive.google.com/uc?export=download&id=1m5UvXkp3BktCU20WOQQ89XALzc5AIJko', //19
    'https://drive.google.com/uc?export=download&id=1uugL68ozVFeQq__XcYiYO4-sC6t0w0DS', //20
    'https://drive.google.com/uc?export=download&id=1ESWIJPxAIvTdjRqrxM7pP95A6NYRGSnA', //21
    'https://drive.google.com/uc?export=download&id=1XWCCo0uejf1yw9dOyBEYesvbPj5EUvGo', //22
    'https://drive.google.com/uc?export=download&id=18fUAVE7UlLi5aAKZTxUhEw88yDEIRS36', //23
    'https://drive.google.com/uc?export=download&id=1yWQ0Vaqt0Kg_Y8t5Lyn4qUYKoTUxUZ4u', //24
  ];
  

export const files = [
  {
    id: 1,
    name: "NEET ANSWER KEY & SOLUTION - XI FULL TEST-1",
    description: "PAPER CODE :- FULL TEST-1 CLASS-XI",
    driveLink: downloadLinks[0],
    code: "MEDJEEX100",
  },
  {
    id: 2,
    name: "NEET ANSWER KEY & SOLUTION - XI FULL TEST-2",
    description: "PAPER CODE :- FULL TEST-2 CLASS-XI",
    driveLink: downloadLinks[1],
    code: "MEDJEEX101",
  },
  {
    id: 3,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-1",
    description: "PAPER CODE :- PART TEST-1 CLASS-XI",
    driveLink: downloadLinks[2],
    code: "MEDJEEX102",
  },
  {
    id: 4,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-2",
    description: "PAPER CODE :- PART TEST-2 CLASS-XI",
    driveLink: downloadLinks[3],
    code: "MEDJEEX103",
  },
  {
    id: 5,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-3",
    description: "PAPER CODE :- PART TEST-3 CLASS-XI",
    driveLink: downloadLinks[4],
    code: "MEDJEEX104",
  },
  {
    id: 6,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-4",
    description: "PAPER CODE :- PART TEST-4 CLASS-XI",
    driveLink: downloadLinks[5],
    code: "MEDJEEX105",
  },
  {
    id: 7,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-5",
    description: "PAPER CODE :- PART TEST-5 CLASS-XI",
    driveLink: downloadLinks[6],
    code: "MEDJEEX106",
  },
  {
    id: 8,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-1",
    description: "PAPER CODE :- FULL TEST-1 CLASS-XI+XII",
    driveLink: downloadLinks[7],
    code: "MEDJEEX107",
  },
  {
    id: 9,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-2",
    description: "PAPER CODE :- FULL TEST-2 CLASS-XI+XII",
    driveLink: downloadLinks[8],
    code: "MEDJEEX108",
  },
  {
    id: 10,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-3",
    description: "PAPER CODE :- FULL TEST-3 CLASS-XI+XII",
    driveLink: downloadLinks[9],
    code: "MEDJEEX109",
  },
  {
    id: 11,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-4",
    description: "PAPER CODE :- FULL TEST-4 CLASS-XI+XII",
    driveLink: downloadLinks[10],
    code: "MEDJEEX110",
  },
  {
    id: 12,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-5",
    description: "PAPER CODE :- FULL TEST-5 CLASS-XI+XII",
    driveLink: downloadLinks[11],
    code: "MEDJEEX111",
  },
  {
    id: 13,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-6",
    description: "PAPER CODE :- FULL TEST-6 CLASS-XI+XII",
    driveLink: downloadLinks[12],
    code: "MEDJEEX112",
  },
  {
    id: 14,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-7",
    description: "PAPER CODE :- FULL TEST-7 CLASS-XI+XII",
    driveLink: downloadLinks[13],
    code: "MEDJEEX113",
  },
  {
    id: 15,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-8",
    description: "PAPER CODE :- FULL TEST-8 CLASS-XI+XII",
    driveLink: downloadLinks[14],
    code: "MEDJEEX114",
  },
  {
    id: 16,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-9",
    description: "PAPER CODE :- FULL TEST-9 CLASS-XI+XII",
    driveLink: downloadLinks[15],
    code: "MEDJEEX115",
  },
  {
    id: 17,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-10",
    description: "PAPER CODE :- FULL TEST-10 CLASS-XI+XII",
    driveLink: downloadLinks[16],
    code: "MEDJEEX116",
  },
  {
    id: 18,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-11",
    description: "PAPER CODE :- FULL TEST-11 CLASS-XI+XII",
    driveLink: downloadLinks[17],
    code: "MEDJEEX117",
  },
  {
    id: 19,
    name: "NEET ANSWER KEY & SOLUTION - XII FULL TEST-1",
    description: "PAPER CODE :- FULL TEST-1 CLASS-XII",
    driveLink: downloadLinks[18],
    code: "MEDJEEX118",
  },
  {
    id: 20,
    name: "NEET ANSWER KEY & SOLUTION - XII FULL TEST-2",
    description: "PAPER CODE :- FULL TEST-2 CLASS-XII",
    driveLink: downloadLinks[19],
    code: "MEDJEEX119",
  },
  {
    id: 21,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-1",
    description: "PAPER CODE :- PART TEST-1 CLASS-XII",
    driveLink: downloadLinks[20],
    code: "MEDJEEX120",
  },
  {
    id: 22,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-2",
    description: "PAPER CODE :- PART TEST-2 CLASS-XII",
    driveLink: downloadLinks[21],
    code: "MEDJEEX121",
  },
  {
    id: 23,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-3",
    description: "PAPER CODE :- PART TEST-3 CLASS-XII",
    driveLink: downloadLinks[22],
    code: "MEDJEEX122",
  },
  {
    id: 24,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-4",
    description: "PAPER CODE :- PART TEST-4 CLASS-XII",
    driveLink: downloadLinks[23],
    code: "MEDJEEX123",
  },
  {
    id: 25,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-5",
    description: "PAPER CODE :- PART TEST-5 CLASS-XII",
    driveLink: downloadLinks[24],
    code: "MEDJEEX124",
  },
];
