import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import image from "../../asset/images/Medjeex_Logo.png";

const Contribute = () => {
  const isLoggedIn = () => !!localStorage.getItem("token");
  const getUserDetails = () => JSON.parse(localStorage.getItem("user"));
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const handleContribute = async () => {
    if (!isLoggedIn()) {
      navigate("/login");
      return;
    }

    const user = getUserDetails();

    try {
      const {
        data: { key },
      } = await axios.get("https://medjeex.com/api/v1/getkey");

      const {
        data: { order },
      } = await axios.post("https://medjeex.com/api/v1/checkout", {
        amount,
      });

      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: `${user.name}'s Contribution`,
        description: "MEDJEEX EDUTECH PRIVATE LIMITED",
        image: image,
        order_id: order.id,
        callback_url: "https://medjeex.com/api/v1/paymentverification",
        prefill: {
          name: user?.name || "Enter Name",
          email: user?.email || "Enter Email",
          contact: user?.phoneNumber || "Enter Contact",
        },
        notes: {
          user_id: user._id,
          product_title: "Contribution",
        },
        theme: {
          color: "#8A8A8A",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("There was an error with the payment process!", error);
      alert("Failed to initiate the payment. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 sm:p-8 bg-gradient-to-r from-blue-400 to-purple-500 animate-[gradient_15s_ease_infinite]">
      <div className="max-w-3xl w-full bg-white bg-opacity-80 p-6 sm:p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">
          Contribute to the Future of Education
        </h1>

        <p className="mb-4 sm:mb-6">
          At our EdTech platform, we believe in the transformative power of technology in education. Your contribution can help us revolutionize learning experiences for students worldwide.
        </p>

        <p className="mb-4 sm:mb-6">
          We're dedicated to making quality education accessible to all. With your support, we can develop innovative tools and resources for students and educators.
        </p>

        <p className="mb-4 sm:mb-6">
          Your donation funds the development of interactive learning modules, AI-powered tutoring systems, and collaborative platforms to enhance educational outcomes.
        </p>

        <p className="mb-4 sm:mb-6">
          We value every contribution, big or small. Your generosity fuels our mission to make learning more engaging, accessible, and effective. Join us in shaping the classrooms of tomorrow.
        </p>

        <p className="mb-4 sm:mb-6">
          Thank you for considering a contribution to our EdTech initiative. Let's revolutionize education together and create brighter futures for learners globally.
        </p>

        <div className="mb-4">
          <input
            type="number"
            placeholder="Enter contribution amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="text-center mt-6">
          <button
            onClick={handleContribute}
            disabled={!amount || parseFloat(amount) < 1}
            className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 ${
              !amount || parseFloat(amount) < 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Contribute Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contribute;
